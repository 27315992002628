const enrollment = [ 
    { text: "USER", value: "user"},
    { text: "EMAIL ADDRESS", value: "email", align: 'center'},
    { text: "NO. OF COURSES", value: "courses_count", align: 'center'},
    //{ text: "STATUS", value: "enrollment_status", align: 'center'},
    //{ text: "DATE ENROLLED", value: "date_enrolled", align: 'center'},
    { text: "ACTION", value: "action", align: 'center', sortable: false}
]

const enrollmentStudent = [ 
    { text: "DATE ENROLLED", value: "created_at"},
    { text: "COURSE", value: "course.title", width: "30%"},
    // { text: "ADMIN STATUS", value: "status", align: 'center'},
    // { text: "STATUS UPDATED ON", value: "updated_at", align: 'center'},
    // { text: "AMOUNT", value: "amount", align: 'center'},
    // { text: "RECEIPT", value: "image", align: 'center'},
    // { text: "OR NUMBER", value: "official_receipt_no", align: 'center'},
]

export { enrollment, enrollmentStudent }