<template>
    <section>
        <section class="d-flex flex-row mb-5">
            <v-text-field
                solo
                dense
                flat
                class="col-xl-3 col-lg-4 custom-border mr-1 general-custom-field f14 poppins fw500"
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Search user"
                v-model="search"
            />
            <v-btn color="primary" @click="getManualEnrollments()">
                Search
            </v-btn>
        </section>
        <v-data-table
            :loading="loading"
            :headers="enrollment"
            :items="enroll_students"
            class="text--center custom-border poppins"
            hide-default-footer
            :items-per-page.sync="paginate">
            <template v-slot:item.user="{ item }">
                <span>
                    {{ item.name ? item.name : '-' }}
                </span>
            </template>
            <template v-slot:item.enrollment_status="{ item }">
                <v-alert v-if="item.status === 'enrolled'" dense color="#7BC14533" class="success--text ma-auto">ENROLLED</v-alert>
                <v-alert v-if="item.status === 'pending'" dense color="#BDBDBD33" class="secondary-2--text ma-auto">PENDING</v-alert>
                <v-alert v-if="item.status === 'disqualified'" dense color="#E49E3233" class="danger--text ma-auto">DISQUALIFIED</v-alert>
            </template>
            <template v-slot:item.date_enrolled="{ item }">
                <span>
                    {{ $dateFormat.mmDDyy(item.created_at) }}
                </span>
            </template>
            <template v-slot:item.action="{ item }">
                <v-btn
                text
                class="primary--text text-capitalize f14 roboto"
                @click="$router.push({ name: 'Instructor Enrollment Student', params: { id: item.id }, query: { timestamp: Date.now() }})"
                >
                <v-icon left>mdi-eye-outline</v-icon>
                View
                </v-btn>
            </template>
        </v-data-table>
        <!-- <ViewEnrollee :dialog="dialog" :user="user" @close="dialog = false"/> -->
        <FormPagination
            :pageCount="pageCount"
            :page="page"
            :paginate="paginate"
            @page="(e) => {page = e, getManualEnrollments()}" 
            @paginate="(e) => {paginate = e, page = 1, getManualEnrollments()}"/>
    </section>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import { enrollment } from '../../constants/tblheaders/enrollment/enrollment'
import ViewEnrollee from '../../components/teacher/enrollment/ViewEnrollee.vue'

export default {
    data: () => ({
        loading: false,
        dialog: false,
        user: null,
        search: '',
        paginate: '10',
        page: 1,
        pageCount: 1,
        enrollment
    }),
    components: {
        ViewEnrollee
    },
    computed: {
        ...mapState('instructor', {
            enroll_students: (state) => state.enroll_students
        })
    },
    methods: {
        ...mapActions('instructor', ['getEnrolledStudentsAction']),
        // getUserEnrollee(user){
        //     this.$api.get(`/instructor/manual/enrollment/user/${user.uuid}`).then((res) => {
        //         this.user = res.data.data
        //         this.dialog = true
        //     })
        // },

        getManualEnrollments(){
            this.loading = true
            this.getEnrolledStudentsAction({ page: this.page, paginate: Number(this.paginate), pageCount: this.pageCount, search: this.search}).then((res) => {
                this.paginate = String(res.per_page)
                this.page = res.page
                this.pageCount = res.last_page
            }).finally(() => {
                this.loading = false
            })
        }
    },
    created() {
        this.getManualEnrollments()
    }
}
</script>